

import { useState, useEffect } from "react";



function useSaveLocalStorage(value: string) {

    const [isLoggedIn, setIsLoggedIn] = useState([]);
    const [walletAddress, setWalletAddress] = useState([]);

    useEffect(() => {
        setIsLoggedIn([]);
        localStorage.setItem('isLoggedIn', value);
    });
}

// function getLocalStorage(key: string, value: string) {

//     localStorage.setItem(key, value);
// }

export default useSaveLocalStorage; 
