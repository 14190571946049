import { ethers } from "ethers";
import saveLocalStorage from "hooks/localStorage";
import { useState } from "react";
import { Alert } from "shared/Alert/Alert";
import erc20abi from '../abis/erc_20_contract_abi.json';

let signer: any = null;
let userAddress: any = null;

const provider = new ethers.providers.Web3Provider(window.ethereum);

export async function connectWallet() {
    
    // window.ethereum.request({
    //   method: "wallet_addEthereumChain",
    //   params: [{
    //     chainId: "0x89",
    //     rpcUrls: ["https://rpc-mainnet.matic.network/"],
    //     chainName: "Matic Mainnet",
    //     nativeCurrency: {
    //       name: "MATIC",
    //       symbol: "MATIC",
    //       decimals: 18
    //     },
    //     blockExplorerUrls: ["https://polygonscan.com/"]
    //   }]
    // });

    await provider.send("eth_requestAccounts", []);
    signer = await provider.getSigner();
    userAddress = await signer.getAddress();
    console.log(`Logged In with ${userAddress} `);
    Alert({children: `Logged In with ${userAddress}`, containerClassName: '', type: 'success'});
    
    saveLocalStorage('true');

    return signer;
}

export async function fetchNativeBalance(accountAddr: string) {
    let balance: any = await provider.getBalance(accountAddr);

    // we use the code below to convert the balance from wei to eth
    balance = ethers.utils.formatEther(balance);
    console.log(balance);
    return balance;

}

export async function signMessage(message: string) {
    if (!signer) { await connectWallet(); }

    let signature = await signer.signMessage(message);
    console.log(signature);
    return signature;
}

export async function fetchERC20Balance(contractAddr: string, accountAddr: string) {
    if (!signer) { await connectWallet(); }

    const erc20 = new ethers.Contract(
        contractAddr,
        erc20abi,
        signer
    );
    let balance = await erc20.balanceOf(accountAddr);
    balance = ethers.utils.formatUnits(balance, 18);
    console.log(balance);
}


export async function handleERC20Transfer(contractAddr: string, to: string, amount: number) {
    if (!signer || signer == null) {
        await connectWallet();
    }

    const erc20 = new ethers.Contract(contractAddr, erc20abi, signer);
    const tx = await erc20.transfer(to, amount);
    console.log(`Transaction hash: ${tx.hash}`);

    const receipt = await tx.wait();
    // console.log(receipt);
    console.log(`Transaction confirmed in block ${receipt.blockNumber}`);
    console.log(`Gas used: ${receipt.gasUsed.toString()}`);

    // handleNotification('Success', 'Voila', `Transfer has been made`);

}

export async function queryHistoricalTx(contractAddr: string) {
    if (!signer || signer == null) {
        await connectWallet();
    }
    const erc20 = new ethers.Contract(contractAddr, erc20abi, signer);
    erc20.on("Transfer", (from, to, amount, event) => {
        console.log({ from, to, amount, event });

        // setTxs((currentTxs) => [
        //   ...currentTxs,
        //   {
        //     txHash: event.transactionHash,
        //     from,
        //     to,
        //     amount: String(amount)
        //   }
        // ]);
    });
}
